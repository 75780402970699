import React, { useState } from 'react'
import styled from "styled-components"

const Wrapper = styled.div`
  .accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  padding: 0 ;
  position:absolute;

}

.accordion-content {
  font-size:13px;
  text-align:center;
  max-width: 139px;
  line-height: 1.3;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;

 

  &:hover,
  &.open {
    color: black;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

`

const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <Wrapper>
        
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          >
          <img src={title} />
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </Wrapper>
    );
  };

export default Accordion;
