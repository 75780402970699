import React, { useState, useContext } from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Netflix from "../images/Hironaka_Clients_Netflix.png"
import Nike from "../images/Hironaka_Clients_Nike.png"
import Venmo from "../images/Hironaka_Clients_Venmo.png"
import ARVR from "../images/Hironaka_Clients_ARVR.png"
import Vans from "../images/Vans_Tall_v5.png"
import Occulus from "../images/oculus.png"
import Portal from "../images/Hironaka_Clients_Portal.png"
import styled from "styled-components"
import Accordion from "../components/Accordion"

const PageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  margin:4em 0 ;

  @media screen and (max-width:500px) {
    margin:4em 0 6em;

  }

  

  &:before {
    content:'';
    background:black;
    width:25px;
    height:6px;
    top:0;
    left:0;
  }

  &:after {
    content:'';
    background:black;
    width:25px;
    height:6px;
    top:0;
    left:0;

    
  }

  h3 {
    margin: 0 0 3px;
  }


`

const Manifesto = styled.h2`
    font-size: 2.2em;
    margin: 0 0 1em;
    max-width: 625px;
    line-height: 1.3;

    @media screen and (max-width: 700px) {
      font-size: 1.6em;

  }
    
}
`

const LogoGarden = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  align-items: center;
  margin-bottom:2em;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }

  img {
    max-width: 80px;
    margin:30px 0 10px;

    @media screen and (max-width: 700px) {
      margin:35px 0 20px;

  }
  }

`

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const siteManifesto = data.site.siteMetadata.description
  const [mobnav, showMobile] = useState(false)

  function closeMobile() {
    showMobile(false)
  }

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

        <div>
          <Manifesto>{siteManifesto}</Manifesto>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>

      <PageSection>
        <div>
          <h3>Our clients </h3>
          <span>(click for more)</span>
        </div>
        <LogoGarden>
          <Accordion title={Nike}>redefining membership as a service</Accordion>

          <Accordion title={Venmo}>
            driving research and positioning for new product launch
          </Accordion>

          <Accordion title={Netflix}>
            creating positioning + go-to-market strategy for key franchises
          </Accordion>

          <Accordion title={ARVR}>
            establishing brand strategy for Facebook’s innovation arm
          </Accordion>

          <Accordion title={Occulus}>
            launching industry-changing hardware
          </Accordion>

          <Accordion title={Vans}>
            developing brand storytelling around business-leading programs
          </Accordion>

          <Accordion title={Portal}>
            building frameworks for integrated marketing approach
          </Accordion>
        </LogoGarden>
      </PageSection>
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        otherCapabilities {
          secondary
        }
        capabilities {
          position
        }
      }
    }
  }
`
export default BlogPostTemplate
